import AuthService from '@/services/auth.service'
import router from '@/router'
import getNewToken from '@/message/get_new_token'

const getPerfilUsuario = async ({ commit }) => {
  try {
    const userProfile = await AuthService.getPerfilUsuario()
    commit('perfilUsuario', userProfile)
    return Promise.resolve(userProfile)
  } catch (error) {
    commit('perfilUsuario', {})
    return Promise.reject(error)
  }
}
const login = async ({ dispatch, commit }, user) => {
  try {
    const userData = await AuthService.login(user)
    const tenant = userData.tenants[0]
    await dispatch('setEmpresaAtual', tenant)
    // await dispatch("menu/getUserMenu", tenant, { root: true })
    await dispatch('getPerfilUsuario')
    await dispatch('setEmpresaAtual', tenant)
    console.log('Getting New Token..')
    getNewToken(tenant)
    console.log('Get It!')
    // await dispatch("menu/getUserMenu", tenant, { root: true })
    await dispatch('getPerfilUsuario')
    commit('loginSuccess', userData)
    return Promise.resolve(userData)
  } catch (error) {
    commit('loginFailure')
    return Promise.reject(error)
  }
}
const refresh = async ({ commit }, token) => {
  try {
    const userData = await AuthService.refresh(token)
    commit('refreshSuccess', userData)
    return Promise.resolve(userData)
  } catch (error) {
    commit('refreshFailure')
    return Promise.reject(error)
  }
}

const reset = async ({ commit }, user) => {
  try {
    const userData = await AuthService.reset(user)
    commit('logout')
    return Promise.resolve(userData)
  } catch (error) {
    commit('loginFailure')
    return Promise.reject(error)
  }
}

const logout = ({ commit }) => {
  AuthService.logout()
  commit('logout')
}

const setEmpresaAtual = async ({ dispatch, commit }, empresa) => {
  dispatch('resetAllStates', null, { root: true })
  dispatch(
    'abilities/updateCollaboratorAbilities',
    empresa.grupo.basic_permissions,
    { root: true }
  )
  await dispatch('setPerfilAtual', {
    empresaAtual: empresa,
    perfil: empresa.perfisColaborador[0],
  })
  await dispatch('getPlantoes')
  // await dispatch("menu/getUserMenu", empresa, { root: true })
  commit('setEmpresaAtual', empresa)
  router.replace('/')
}

const setPerfilAtual = async (
  { dispatch, commit },
  { empresaAtual, perfil }
) => {
  if (typeof perfil === 'number') {
    dispatch('resetAllStates', null, { root: true })
    dispatch('abilities/updateTenantAbilities', empresaAtual.perfis[perfil], {
      root: true,
    })
    commit('setPerfilAtual', perfil)
    const menus = await dispatch(
      'gerencial/getMenusByPerfilId',
      { perfilId: perfil, params: { grupo: empresaAtual.grupo.public_id } },
      { root: true }
    )
    commit('menu/setMenuItems', menus, { root: true })
  }
}

const updatePerfilUsuario = async ({ commit }, perfilUsuario) => {
  try {
    const novoPerfil = await AuthService.updatePerfilUsuario(perfilUsuario)
    commit('perfilUsuario', novoPerfil)
    return Promise.resolve(novoPerfil)
  } catch (error) {
    commit('perfilUsuario', {})
    return Promise.reject(error)
  }
}

const changePassword = async (_, { passwordInfo, isManager, usuario }) => {
  try {
    await AuthService.changePassword(passwordInfo, isManager, usuario)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
const getPlantoes = async ({ commit }, params = null) => {
  try {
    if (params == null) {
      params = { data: new Date() }
    }
    const data = await AuthService.getPlantoes(params)
    console.log(data)
    commit('setPlantoes', data)
    return Promise.resolve(data)
  } catch (e) {
    commit('setPlantoes', {})
    return Promise.reject(e)
  }
}

export default {
  getPerfilUsuario,
  login,
  refresh,
  reset,
  logout,
  setEmpresaAtual,
  setPerfilAtual,
  updatePerfilUsuario,
  changePassword,
  getPlantoes,
}
